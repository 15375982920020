'use client';

import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from '../router-link/router-link';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.main;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.main;

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 120,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="330" height="92" viewBox="0 0 330 92">
        <defs>
          <style>
            {`
              .cls-1 {
                fill: #ffffff;
                stroke-width: 0px;
              }
              .cls-2 {
                fill: transparent;
              }
            `}
          </style>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <path
          d="M15.7142 91.2309C13.712 91.2309 11.8378 90.8169 10.1404 90.0002L10.1216 89.9927C7.87859 88.8787 5.96673 87.5201 4.44251 85.9507C3.1968 84.6711 2.207 83.2523 1.49193 81.7356C0.306432 79.2103 0.280087 77.2495 0.287614 76.9371V67.9462L0.362884 67.709C0.396756 67.6037 0.716653 66.6515 1.49193 65.7031C2.5984 64.3482 4.08122 63.6332 5.77856 63.6332C5.8877 63.6332 5.99684 63.6332 6.10598 63.6407C7.88611 63.7348 9.38399 64.5628 10.4415 66.0305C11.1867 67.0655 11.4464 68.0741 11.4765 68.187L11.5217 68.3714V76.2108C12.4964 78.6382 13.9566 79.8162 15.9701 79.8162C17.0766 79.8162 17.9949 79.4512 18.1981 79.3608L46.0442 63.1289C46.9587 61.552 47.2636 60.1181 46.9512 58.8648C46.5034 57.0809 44.9001 56.1024 44.7985 56.0422L44.7797 56.0309L21.175 42.4334C20.9643 42.3092 19.0976 41.1689 18.5481 39.05C18.2169 37.778 18.4465 36.4269 19.2029 35.1397C20.332 33.2317 22.1498 32.1816 24.3213 32.1816C25.4805 32.1816 26.3498 32.4865 26.4439 32.5241L26.5681 32.5693L48.9873 45.2748C49.3561 45.4781 55.0352 48.677 57.1653 53.4454L57.1842 53.4906L57.1992 53.5357C58.2568 56.3546 58.6594 59.1057 58.4035 61.71C58.1928 63.8214 57.5492 65.8311 56.4917 67.6827C54.6965 70.829 52.2991 72.4398 52.1975 72.5075L52.1599 72.5339L22.8799 89.5486L22.8498 89.5637C22.7218 89.6314 19.632 91.2234 15.718 91.2234L15.7142 91.2309Z"
          fill="#74f932"
        />
        <path
          d="M23.9374 67.1407C22.5449 67.1407 21.4573 66.6439 21.3369 66.5875L21.2766 66.5574L6.88879 58.1422C6.66675 58.0142 5.08231 57.0733 3.5167 55.1954C1.3414 52.591 0.163424 49.3582 0.106971 45.8468L0.0994442 45.3651C0.0467553 42.1511 -0.0623859 35.4634 0.0467555 29.0616C0.23493 18.0572 0.957522 15.4001 1.48065 14.3577C2.99357 11.3582 5.14253 8.94199 7.70171 7.36508C9.734 6.11184 13.505 4.76074 16.9712 4.76074C18.661 4.76074 20.0874 5.08817 21.2089 5.72796C21.8788 6.10807 27.2493 9.17909 72.8365 35.2413L72.8817 35.2676L72.9269 35.2978C73.021 35.3617 75.2753 36.8747 77.0479 39.8328C78.0904 41.5753 78.7641 43.4683 79.0501 45.4592C79.4039 47.9243 79.163 50.5286 78.3275 53.2082V53.2233C76.0732 60.2008 69.9462 62.2482 69.6865 62.331L69.3817 62.4288L69.0618 62.4025C68.6892 62.3686 65.366 62.0111 63.7552 59.5497C63.1154 58.5712 62.5246 56.8852 63.3563 54.4013L63.5181 53.9158L63.9359 53.6185C65.2907 52.6512 67.5752 50.5286 67.9327 48.963C68.2075 47.7625 67.7859 46.5732 67.5112 45.9673C67.037 44.9285 66.4386 44.3527 66.2241 44.2398L66.2015 44.2285L17.69 16.3598C17.4078 16.2733 16.6137 16.055 15.6878 16.055C13.4034 16.055 12.1163 17.297 11.6459 19.9653L11.612 45.7339V45.7603C11.6045 46.0162 11.6722 48.1463 14.8298 49.727L14.8749 49.7496L26.3611 56.4486C26.5267 56.5427 27.6106 57.1938 28.4499 58.368C29.6956 60.1105 29.8085 62.1465 28.7698 64.1036C27.7273 66.0643 26.0111 67.1445 23.9374 67.1445V67.1407Z"
          fill="#74f932"
        />
        <path
          d="M23.9374 67.1407C22.5449 67.1407 21.4573 66.6439 21.3369 66.5875L21.2766 66.5574L6.88879 58.1422C6.66675 58.0142 5.08231 57.0733 3.5167 55.1954C1.3414 52.591 0.163424 49.3582 0.106971 45.8468L0.0994442 45.3651C0.0467553 42.1511 -0.0623859 35.4634 0.0467555 29.0616C0.23493 18.0572 0.957522 15.4001 1.48065 14.3577C2.99357 11.3582 5.14253 8.94199 7.70171 7.36508C9.734 6.11184 13.505 4.76074 16.9712 4.76074C18.661 4.76074 20.0874 5.08817 21.2089 5.72796C21.8788 6.10807 27.2493 9.17909 72.8365 35.2413L72.8817 35.2676L72.9269 35.2978C73.021 35.3617 75.2753 36.8747 77.0479 39.8328C78.0904 41.5753 78.7641 43.4683 79.0501 45.4592C79.4039 47.9243 79.163 50.5286 78.3275 53.2082V53.2233C76.0732 60.2008 69.9462 62.2482 69.6865 62.331L69.3817 62.4288L69.0618 62.4025C68.6892 62.3686 65.366 62.0111 63.7552 59.5497C63.1154 58.5712 62.5246 56.8852 63.3563 54.4013L63.5181 53.9158L63.9359 53.6185C65.2907 52.6512 67.5752 50.5286 67.9327 48.963C68.2075 47.7625 67.7859 46.5732 67.5112 45.9673C67.037 44.9285 66.4386 44.3527 66.2241 44.2398L66.2015 44.2285L17.69 16.3598C17.4078 16.2733 16.6137 16.055 15.6878 16.055C13.4034 16.055 12.1163 17.297 11.6459 19.9653L11.612 45.7339V45.7603C11.6045 46.0162 11.6722 48.1463 14.8298 49.727L14.8749 49.7496L26.3611 56.4486C26.5267 56.5427 27.6106 57.1938 28.4499 58.368C29.6956 60.1105 29.8085 62.1465 28.7698 64.1036C27.7273 66.0643 26.0111 67.1445 23.9374 67.1445V67.1407Z"
          fill="#74f932"
        />
        <path
          d="M324.494 0H271.534C269.04 0 267.018 2.02197 267.018 4.5162V17.4363C267.018 19.9305 269.04 21.9525 271.534 21.9525H324.494C326.988 21.9525 329.01 19.9305 329.01 17.4363V4.5162C329.01 2.02197 326.988 0 324.494 0Z"
          fill="#74f932"
        />
        <path
          d="M292.06 4.95278C292.877 5.38182 293.52 6.01785 293.991 6.85711C294.458 7.70014 294.695 8.71628 294.695 9.90554V17.6471H292.004V10.312C292.004 9.13779 291.71 8.23455 291.123 7.60981C290.536 6.98131 289.734 6.66894 288.718 6.66894C287.702 6.66894 286.897 6.98131 286.302 7.60981C285.707 8.23832 285.41 9.13779 285.41 10.312V17.6471H282.719V10.312C282.719 9.13779 282.426 8.23455 281.839 7.60981C281.251 6.98131 280.45 6.66894 279.434 6.66894C278.418 6.66894 277.612 6.98131 277.018 7.60981C276.423 8.23832 276.126 9.13779 276.126 10.312V17.6471H273.408V4.52374H276.126V6.02538C276.57 5.4872 277.134 5.06569 277.815 4.76461C278.497 4.46353 279.227 4.31299 280.006 4.31299C281.052 4.31299 281.989 4.53503 282.817 4.97913C283.641 5.42322 284.277 6.06678 284.721 6.9098C285.117 6.1157 285.738 5.48344 286.581 5.01676C287.424 4.55009 288.327 4.31299 289.298 4.31299C290.329 4.31299 291.255 4.52751 292.072 4.95655L292.06 4.95278Z"
          fill="black"
        />
        <path
          d="M297.589 7.52687C298.138 6.51073 298.884 5.72039 299.828 5.15587C300.773 4.59134 301.815 4.30908 302.959 4.30908C303.991 4.30908 304.894 4.51231 305.662 4.91501C306.433 5.32146 307.047 5.82577 307.509 6.42793V4.5236H310.249V17.6469H307.509V15.6937C307.05 16.3146 306.422 16.8302 305.628 17.2405C304.834 17.6544 303.927 17.8614 302.91 17.8614C301.781 17.8614 300.75 17.5716 299.813 16.9921C298.876 16.4125 298.135 15.6033 297.585 14.5608C297.036 13.5221 296.765 12.3404 296.765 11.0232C296.765 9.70594 297.039 8.53925 297.585 7.52311L297.589 7.52687ZM306.949 8.71614C306.576 8.05 306.087 7.54193 305.485 7.19192C304.883 6.84192 304.231 6.66879 303.531 6.66879C302.831 6.66879 302.18 6.83815 301.578 7.18063C300.976 7.52311 300.487 8.02365 300.114 8.68227C299.742 9.34088 299.553 10.1237 299.553 11.0269C299.553 11.9302 299.742 12.728 300.114 13.4092C300.487 14.0904 300.98 14.6135 301.589 14.9711C302.199 15.3286 302.846 15.5055 303.531 15.5055C304.216 15.5055 304.883 15.3324 305.485 14.9824C306.087 14.6324 306.576 14.1205 306.949 13.4469C307.321 12.7732 307.509 11.9829 307.509 11.0759C307.509 10.1688 307.321 9.38604 306.949 8.71614Z"
          fill="black"
        />
        <path
          d="M318.965 11.0043L323.207 17.6507H320.136L317.302 13.1947L314.634 17.6507H311.8L316.041 11.1963L311.8 4.52734H314.871L317.705 8.98332L320.373 4.52734H323.207L318.965 11.0043Z"
          fill="black"
        />
        <path
          d="M330 67.3593V78.6272H323.237C318.42 78.6272 314.66 77.4492 311.969 75.0895C309.274 72.7336 307.927 68.8873 307.927 63.5506V46.2988H302.643V35.2642H307.927V24.6963H321.216V35.2642H329.921V46.2988H321.216V63.7087C321.216 65.0033 321.528 65.9367 322.149 66.505C322.77 67.077 323.805 67.3593 325.258 67.3593H330Z"
          fill="#74f932"
        />
        <path
          d="M291.688 36.856C294.021 35.4823 296.61 34.7974 299.459 34.7974V48.8653H295.805C292.489 48.8653 290.002 49.5766 288.346 51.003C286.686 52.4293 285.858 54.9283 285.858 58.5036V78.6308H272.569V35.2678H285.858V42.4937C287.412 40.1114 289.354 38.2334 291.688 36.8598V36.856Z"
          fill="#74f932"
        />
        <path
          d="M219.688 45.1318C221.37 41.7635 223.662 39.1742 226.564 37.3602C229.466 35.5462 232.702 34.6392 236.278 34.6392C239.334 34.6392 242.017 35.2601 244.32 36.5059C246.623 37.7478 248.4 39.3812 249.642 41.4022V35.2639H262.931V78.6269H249.642V72.4887C248.347 74.5097 246.544 76.143 244.241 77.385C241.934 78.6269 239.255 79.2517 236.199 79.2517C232.676 79.2517 229.462 78.3334 226.56 76.493C223.659 74.6527 221.367 72.037 219.684 68.6424C217.998 65.2515 217.159 61.3261 217.159 56.8701C217.159 52.4142 218.002 48.5039 219.684 45.1356L219.688 45.1318ZM246.887 49.0948C245.047 47.1791 242.807 46.2194 240.165 46.2194C237.523 46.2194 235.28 47.1641 233.444 49.0571C231.603 50.9502 230.685 53.5507 230.685 56.8664C230.685 60.182 231.603 62.8127 233.444 64.7547C235.28 66.6966 237.523 67.6676 240.165 67.6676C242.807 67.6676 245.047 66.7079 246.887 64.7923C248.727 62.8767 249.646 60.261 249.646 56.9416C249.646 53.6222 248.724 51.0104 246.887 49.091V49.0948Z"
          fill="#74f932"
        />
        <path
          d="M205.699 39.6938C208.887 42.9568 210.479 47.4918 210.479 53.2951V78.631H197.269V55.0828C197.269 52.2865 196.531 50.1225 195.052 48.5945C193.577 47.0665 191.541 46.3025 188.952 46.3025C186.362 46.3025 184.326 47.0665 182.851 48.5945C181.376 50.1225 180.634 52.2865 180.634 55.0828V78.631H167.425V55.0828C167.425 52.2865 166.687 50.1225 165.208 48.5945C163.733 47.0665 161.697 46.3025 159.107 46.3025C156.518 46.3025 154.482 47.0665 153.007 48.5945C151.531 50.1225 150.79 52.2865 150.79 55.0828V78.631H137.501V35.2679H150.79V40.7062C152.137 38.8922 153.899 37.4545 156.074 36.3932C158.249 35.3319 160.71 34.8013 163.458 34.8013C166.721 34.8013 169.637 35.5013 172.2 36.9013C174.763 38.3013 176.773 40.296 178.222 42.8853C179.724 40.503 181.771 38.561 184.36 37.0556C186.95 35.554 189.772 34.8013 192.832 34.8013C198.221 34.8013 202.508 36.4346 205.692 39.6976L205.699 39.6938Z"
          fill="#74f932"
        />
        <path
          d="M98.7256 77.227C95.5643 75.9323 93.039 74.0167 91.1497 71.4763C89.2567 68.9397 88.2593 65.88 88.1577 62.3047H102.301C102.508 64.3257 103.208 65.8687 104.401 66.93C105.594 67.9913 107.145 68.522 109.064 68.522C110.983 68.522 112.587 68.0704 113.727 67.1634C114.867 66.2564 115.436 64.9994 115.436 63.3961C115.436 62.0488 114.98 60.9348 114.077 60.0541C113.17 59.1735 112.056 58.4471 110.735 57.8788C109.414 57.3105 107.536 56.6632 105.101 55.9369C101.578 54.8492 98.703 53.7615 96.475 52.6739C94.2471 51.5862 92.3277 49.9792 90.7244 47.8566C89.1174 45.734 88.3158 42.9603 88.3158 39.5431C88.3158 34.4661 90.1524 30.4881 93.8331 27.6128C97.51 24.7375 102.305 23.2998 108.21 23.2998C114.115 23.2998 119.064 24.7375 122.74 27.6128C126.417 30.4881 128.386 34.4887 128.645 39.6183H114.269C114.163 37.857 113.516 36.472 112.327 35.4597C111.134 34.451 109.606 33.943 107.743 33.943C106.136 33.943 104.841 34.372 103.859 35.2263C102.873 36.0806 102.384 37.3113 102.384 38.9183C102.384 40.6796 103.212 42.0533 104.871 43.0356C106.527 44.0216 109.12 45.0829 112.643 46.2233C116.166 47.4163 119.026 48.5529 121.231 49.6443C123.433 50.7319 125.337 52.3126 126.944 54.3863C128.551 56.46 129.353 59.1283 129.353 62.3913C129.353 65.6542 128.563 68.3225 126.982 70.8629C125.401 73.4032 123.109 75.4242 120.106 76.9259C117.099 78.4275 113.55 79.1802 109.459 79.1802C105.368 79.1802 101.894 78.5329 98.7331 77.2383L98.7256 77.227Z"
          fill="#74f932"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

export default Logo;
